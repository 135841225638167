import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import mutations from './mutations';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    userSnapshot: {},
    userId: '',
    rootIp: '',
    userSubscription: '',
    subscriptionExpired: true, // returns true if has expired OR no sub.
    isUserLoggedIn: false,
    instanceError: null,
    actionImage: null,
    userImages: [],
    userImageName: '',
    finalImage: null,
    showLeavePageConfirmation: false,
    isLandingVisited: false,

    zipDirectory: '',
    zipDownload: null,
    jobId: '',
    queueDone: false,
    queueError: null,

    selectedSubscription: '',
    remainingQuota: 0,
    lastPaymentDate: '',
    paymentMade: false,

    loading: false,
    alertObject: {
      show: false,
      title: '',
      content: '',
      type: 'primary',
    },
    snackbarObject: {
      show: false,
      content: '',
    },
    goProObject: {
      show: false,
      title: '',
      type: '',
    },

    userBrowser: '',
    userBrand: '',
  },

  getters: {
    getUser: (state) => state.user,
    getUserId: (state) => state.userId,
    getRootIp: (state) => state.rootIp,
    getInstanceError: (state) => state.instanceError,
    getLandingPageVisited: (state) => state.isLandingVisited,

    getLoading: (state) => state.loading,
    getUserImage: (state) => state.actionImage,
    getIsUserLoggedIn: (state) => state.isUserLoggedIn,
    getImageName: (state) => state.userImageName,
    getAlertObject: (state) => state.alertObject,
    getProObject: (state) => state.goProObject,
    getSnackbarObject: (state) => state.snackbarObject,

    getZipDirectory: (state) => state.zipDirectory,
    getFinalImage: (state) => state.finalImage, // use for preview image
    getJobId: (state) => state.jobId,
    getQueueError: (state) => state.queueError,
    isQueueDone: (state) => state.queueDone,

    isUserSubscribed: (state) => !state.subscriptionExpired,
    getRemainingQuota: (state) => state.remainingQuota,
    lastPaymentDate: (state) => state.lastPaymentDate,
    showLeavePageConfirmation: (state) => state.showLeavePageConfirmation,
    getUserBrowser: (state) => state.userBrowser,
  },

  mutations,
  actions,
});
