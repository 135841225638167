<template>
  <div id="app">
    <!-- region Dialogs -->
    <md-dialog-alert
      :md-active.sync="getAlertObject.show"
      :md-title="getAlertObject.title"
      :md-content="getAlertObject.content"
      @md-closed="onCloseAlert"
    />
    <md-dialog-confirm
      :md-active.sync="showUpdateUI"
      md-title="An Update is available! 🤓"
      md-content="Sorry for the inconvenience, but there's an update available!
      Please restart the app to get the latest features."
      @md-closed="accept">
    </md-dialog-confirm>
    <md-snackbar md-position="center"
                 :md-duration="5000"
                 :md-active="getSnackbarObject.show"
                 md-persistent>
      <span>{{ getSnackbarObject.content }}</span>
      <md-button class="md-primary"
                 @click="$store.commit('SET_SNACKBAR', {show: false, content: ''})">
        OK
      </md-button>
    </md-snackbar>
    <pro-dialog/>
    <!-- endregion Dialogs -->

    <!-- region Main View -->
    <main>
      <Fac3dHome/>
      <router-view></router-view>

      <div style="min-width:250px;height:250px;">
        <Adsense
          data-ad-client="ca-pub-4439890199834207"
          data-ad-slot="responsive">
        </Adsense>
      </div>
      <br/>
      <br/>
      <br/>

      <Footer />
      <picture v-if="showSvg">
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 24 150 28" preserveAspectRatio="none">
          <defs>
            <linearGradient id="wave-gradient" gradientTransform="rotate(90)">
              <stop offset="5%" stop-color="#4286f4" />
              <stop offset="35%" stop-color="#373b44" />
            </linearGradient>
            <path id="a"
                  d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18v44h-352z"/>
          </defs>
          <g class="wave-paths">
            <use xlink:href="#a" x="0" />
            <use xlink:href="#a" x="50" y="3" />
            <use xlink:href="#a" x="100" y="5" />
            <use xlink:href="#a" x="150" y="7" />
          </g>
        </svg>
      </picture>
    </main>
    <!-- endregion Main View -->

    <AdComponent></AdComponent>
  </div>
</template>
<script type="text/babel">
import { mapGetters } from 'vuex';
import Fac3dHome from '@/components/Home.vue';
import Footer from '@/components/Footer.vue';
import ProDialog from './components/ProDialog.vue';
import AdComponent from './components/AdComponent.vue';

export default {
  components: {
    ProDialog,
    Footer,
    Fac3dHome,
    AdComponent,
  },
  name: 'Fac3DApp',
  computed: {
    ...mapGetters(['getAlertObject', 'getSnackbarObject', 'getLoading']),
    screenHeight() {
      return window.screen.height;
    },
    screenWidth() {
      return window.screen.width;
    },
    showSvg() {
      return ['/'].includes(this.$route.path);
    },
  },
  data() {
    return {
      showUpdateUI: false,
    };
  },
  methods: {
    onCloseAlert() {
      this.$store.dispatch('setAlert', {
        show: false,
        title: '',
        content: '',
      });
    },
    decideBrand() {
      return navigator.userAgent.indexOf('CriOS') >= 0
        ? this.$store.commit('SET_USER_BRAND', 'apple') : this.$store.commit('SET_USER_BRAND', 'android');
    },
    decideBrowser() {
      let res = '';
      if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
        res = 'Opera';
      } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
        res = 'Chrome';
      } else if (navigator.userAgent.indexOf('Safari') !== -1) {
        res = 'Safari';
      } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
        res = 'Firefox';
      } else if ((navigator.userAgent.indexOf('MSIE') !== -1) || (!!document.documentMode === true)) {
        res = 'IE';
      } else {
        res = 'unknown';
      }
      return this.$store.commit('SET_USER_BROWSER', res);
    },
    async getSubscription() {
      try {
        await this.$store.dispatch('checkUserQuota');
      } catch (e) {
        throw new Error(e);
      }
    },
    async accept() {
      this.showUpdateUI = false;
      await this.$workbox.messageSW({ type: 'SKIP_WAITING' });
    },
  },
  async mounted() {
    this.decideBrand();
    this.decideBrowser();
    await this.$store.dispatch('checkApi');
    await this.getSubscription();
    this.$analytics.logEvent('application_opened.');
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', () => {
        this.showUpdateUI = true;
      });
    }
  },
};
</script>
<style lang="scss">
// Colors
$bg-color: hsl(256,33,10);
$dot-color: hsl(256,33,70);

// Dimensions
$dot-size: 1px;
$dot-space: 22px;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,700;1,200;1,400;1,700&display=swap');
#app {
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
  //background: linear-gradient(to right, #b92b27, #1565c0);
}

main {
  min-height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  //background-image: linear-gradient(-225deg, #FF3CAC 0%, #562B7C 52%, #2B86C5 100%);
  //background-image: linear-gradient(to right, #003a3a 0%, #660567 100%);
  background:
    linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
    linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
    $dot-color;
  background-size: $dot-space $dot-space;
}

.header {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin: 0;
    border: 0;
  }
  h1 {
    font-weight: 300;
    font-size: 3rem;
    color: white;
    margin: 0;
  }
}

.waves {
  position: absolute;
  top: 65%;
  left: 0;
  width: 100%;
  height: 40vh;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
}

/* Animation */
.wave-paths {
  use {
    animation: move-waves 10s ease-in-out infinite;
    fill: url(#wave-gradient);
  }
  use:nth-child(odd) {
    animation-direction: reverse;
    animation-duration: 13s;
  }
  use:nth-child(1) {
    animation-delay: -2s;
    opacity: .7;
  }
  use:nth-child(2) {
    animation-delay: -3s;
    opacity: .5;
  }
  use:nth-child(3) {
    animation-delay: -4s;
    opacity: .3;
  }
  use:nth-child(4) {
    animation-delay: -5s;
  }
}

@keyframes move-waves {
  0% {
    transform: translate3d(-30px, 0, 0);
  }
  50% {
    transform: translate3d(30px, 0, 0);
  }
  100% {
    transform: translate3d(-30px, 0, 0);
  }
}
.rest {
  z-index: 11;
  position: relative;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  /* color: #2c3e50; */
  color: #003062;
}

#nav a.router-link-exact-active {
  color: #0a9cf5;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s ease-out;
}

.slide-enter-to {
  position: absolute;
  right: 0;
}

.slide-enter-from {
  position: absolute;
  right: -100%;
}

.slide-leave-to {
  position: absolute;
  left: -100%;
}

.slide-leave-from {
  position: absolute;
  left: 0;
}
</style>
