/* eslint-disable no-console */

// import { register } from 'register-service-worker';
//
// if (process.env.NODE_ENV === 'production') {
//   register(`${process.env.BASE_URL}service-worker.js`, {
//     ready() {
//       console.log(
//         'App is being served from cache by a service worker.\n'
//         + 'For more details, visit https://goo.gl/AFskqB',
//       );
//     },
//     registered() {
//       console.log('Service worker has been registered.');
//       // Check Hourly for Updates:
//       // setInterval(() => {
//       //   registration.update();
//       // }, 1000 * 60 * 60);
//     },
//     cached() {
//       console.log('Content has been cached for offline use.');
//     },
//     updatefound() {
//       console.log('New content is downloading.');
//     },
//     updated(registration) {
//       console.log('New content is available; please refresh.');
//       document.dispatchEvent(
//         new CustomEvent('swUpdated', { detail: registration }),
//       );
//     },
//     offline() {
//       console.log('No internet connection found. App is running in offline mode.');
//     },
//     error(error) {
//       console.error('Error during service worker registration:', error);
//     },
//   });
// }
import { Workbox } from 'workbox-window';

// eslint-disable-next-line import/no-mutable-exports
let wb = null;

if ('serviceWorker' in navigator) {
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);
  wb.addEventListener('controlling', () => {
    window.location.reload();
  });
  wb.register();
} else {
  wb = null;
}

// eslint-disable-next-line no-restricted-globals
self.addEventListener('fetch', (event) => {
  event.respondWith(fetch(event.request));
});
export default wb;
