<template>
  <div id="hello--id">
    <!-- region Support Dialog -->
    <md-dialog :md-active.sync="showSupportDialog" class="support-dialog">
      <md-dialog-title>Be on the edge</md-dialog-title>
      <md-dialog-content>

        <CustomRequestComponent />
        <br/>
        <div class="custom-req-wrapper">
          <h2 class="reveal-text">More features?</h2>
          <p>We have a few more products to launch very soon, focused mostly on 3D and graphics.
            <br/>Wanna be notified when they're ready?</p>
          <form name="contact" class="md-layout" netlify>
            <md-field>
              <label for="name">Name</label>
              <md-input id="name" type="text" name="name" v-model="name"/>
            </md-field>
            <md-field>
              <label for="email">Email</label>
              <md-input type="email" id="email" name="email" v-model="emailSub" />
            </md-field>
            <p>
              <md-button type="submit" class="md-raised md-primary" @click.prevent="onSubscribe">
                Subscribe
              </md-button>
            </p>
          </form>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="toggleSupport">Okay then</md-button>
      </md-dialog-actions>
    </md-dialog>
    <!-- endregion Support Dialog -->

    <!-- region Drawer -->
    <md-drawer :md-active.sync="menuVisible">
      <md-toolbar class="md-transparent" md-elevation="0">
        <span>Menu</span>
        <div class="md-toolbar-section-end">
          <md-button class="md-icon-button md-dense" @click="toggleMenu">
            <md-icon>keyboard_arrow_left</md-icon>
          </md-button>
        </div>
      </md-toolbar>
      <md-list>
        <md-list-item @click.prevent="goHome">
          <md-icon>home</md-icon>
          <span class="md-list-item-text">Home</span>
        </md-list-item>
        <md-divider></md-divider>
        <md-list-item @click.prevent="goLanding">
          <md-icon>face</md-icon>
          <span class="md-list-item-text">About Us</span>
        </md-list-item>
        <md-list-item @click.prevent="goAbout">
          <md-icon>info</md-icon>
          <span class="md-list-item-text">Terms</span>
        </md-list-item>
        <md-list-item @click.prevent="toggleSupport">
          <md-icon>help_outline</md-icon>
          <span class="md-list-item-text">Contact</span>
        </md-list-item>
        <md-divider></md-divider>
        <slot v-if="!getIsUserLoggedIn">
          <md-list-item @click="goAuth('Register')">
            <md-icon>app_registration</md-icon>
            <span class="md-list-item-text">Sign Up</span>
          </md-list-item>
          <md-list-item @click="goAuth('Login')">
            <md-icon>login</md-icon>
            <span class="md-list-item-text">Login</span>
          </md-list-item>
        </slot>
        <slot v-else>
          <md-list-item @click.prevent="goProfile">
            <md-icon>perm_identity</md-icon>
            <span class="md-list-item-text">Profile</span>
          </md-list-item>
          <md-list-item @click.prevent="goBilling">
            <md-icon>attach_money</md-icon>
            <span class="md-list-item-text">Billing</span>
          </md-list-item>
          <md-list-item @click.prevent="onlogout">
            <md-icon>logout</md-icon>
            <span class="md-list-item-text">Logout</span>
          </md-list-item>
        </slot>
      </md-list>
    </md-drawer>
    <!-- endregion Drawer -->

    <!-- region Toolbar/Navbar -->
    <md-toolbar class="md-large hero-wrapper giphy-bck">
      <md-toolbar class="naked--bar">
        <md-button class="md-icon-button" @click="toggleMenu" v-if="!menuVisible">
          <md-icon>menu</md-icon>
        </md-button>
        <!-- <span class="md-title">FAC3D</span> -->
        <md-button class="md-icon-button" @click.prevent="onReload"
                   v-if="$route.path !== '/'">
          <md-icon>keyboard_backspace</md-icon>
        </md-button>
      </md-toolbar>

      <HeaderLogo/>
    </md-toolbar>
    <!-- endregion Toolbar/Navbar -->

    <div class="error-wrapper" v-if="getInstanceError !== null">
      <p class="text-danger">
        The processing instance is currently unavailable 🤕 Please try again later 🤓
      </p>
    </div>

    <div class="poster-body" v-if="$route.path === '/' && getInstanceError === null">
      <div class="poster-text">
        <h4 v-if="getIsUserLoggedIn">
          {{ getIsUserLoggedIn ? `Greetings ${getUser.displayName || ''}` : '' }}
          <br/>
          <span>Bring any face to life.</span>
        </h4>
        <h4 v-else>
          <span>Upload an image with a face in it.</span><br/>
          Support us with a small monthly fee, or watch an ad<br/>
          <span>Transform your image in 3D!</span>
        </h4>
      </div>
    </div>

    <md-snackbar md-position="center" :md-duration="Infinity"
      :md-active.sync="showSnackbar" md-persistent>
      <span>Our processing instance is not available right now. Please come back later!</span>
      <md-button class="md-accent" @click="hideSnackbar">OK</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Fac3dHome',
  components: {
    CustomRequestComponent: () => import('./CustomRequest.vue'),
    HeaderLogo: () => import('./HeaderLogo.vue'),
  },
  computed: {
    ...mapGetters([
      'getIsUserLoggedIn',
      'getInstanceError',
      'getUser',
    ]),
  },
  data() {
    return {
      showSupportDialog: false,
      showSnackbar: false,
      loading: false,
      menuVisible: false,
      emailSub: '',
      name: '',
    };
  },
  methods: {
    onReload() {
      // this.toggleMenu();
      // this.$router.go(this.$router.currentRoute);
      this.$router.go(-1);
    },
    goHome() {
      this.toggleMenu();
      this.$router.push({ path: '/' });
    },
    goProfile() {
      this.toggleMenu();
      this.$router.push({ name: 'UserProfile' });
    },
    goBilling() {
      this.toggleMenu();
      this.$router.push({ name: 'UserBilling' });
    },
    goAuth(name) {
      this.toggleMenu();
      this.$router.push({ name });
    },
    goAbout() {
      this.toggleMenu();
      this.$router.push({ path: '/terms' });
    },
    async onlogout() {
      this.toggleMenu();
      try {
        await this.$store.dispatch('userLogout');
        await this.$router.push({ path: '/' });
      } catch (e) {
        throw new Error(e);
      }
    },
    toggleSupport() {
      this.toggleMenu();
      this.showSupportDialog = !this.showSupportDialog;
    },
    onSubscribe() {
      this.loading = true;
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    hideSnackbar() {
      this.showSnackbar = false;
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    goLanding() {
      window.location.href = 'https://landing.fac3d.xyz';
    },
  },
  watch: {
    getInstanceError(val) {
      if (val !== null) {
        this.showSnackbar = true;
      }
    },
  },
};
</script>

<style lang="scss">
$poppins: 'Poppins', sans-serif;
$desktop-width: 625px;
$font-size-base: 1rem;
$font-weight-normal: 400;
$font-weight-bold: 600;
$line-height-base: 1.5;

$h1: 2.5rem;
$h2: 2rem;
$h3: 1.75rem;
$h4: 1.75rem;
$h5: 1.25rem;
$h6: 1rem;

$height: 100%;
$width: 100%;
$background-white: #f4f3f1;
$body-color: #282828;

$overlay-purple: #4d5494;
$pinkest: #ff184c;
$blackest: #1B1B1B;
$greyer: #B8B8B8;

.error-wrapper {
  max-width: 50%;
  margin: 0 auto;
  p {
    color: $pinkest;
    background: #fbdee8;
    padding: 1rem;
    border-radius: 6px;
  }
}

.face-logo {
  //max-width: 120px;
  border-radius: 32px;
  //padding: 1rem;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.hero-wrapper {
  background-color: $greyer !important;
  box-shadow: none !important;
  button.md-icon-button {
    background-color: transparent !important;
  }
}
.md-drawer {
  z-index: 999 !important;
  button.md-icon-button {
    background-color: transparent !important;
  }
}
iframe#yt-frame {
  position: absolute;
  // height: inherit;
  // min-height: 184px;
  top: 0;
  left: 0;
}

.support-dialog {
  padding: 1rem;
  margin: 1rem;
  .md-dialog .md-dialog-container {
    max-width: 768px;
  }
}

#hello--id {
  a {
    &:hover {
      text-decoration: none !important;
    }
  }
}

.poster-heading {
  display: flex;
  flex: 1 0 80%;

  h1 {
    font-family: $poppins;
    letter-spacing: -.05rem;
    width: $width;
    text-align: left;
    font-weight: 100;
    font-size: 69px;
    line-height: 100%;
    //color: #00C6C6;
    color: white;
  }
}

.poster-sub-heading {
  align-self: flex-end;
  display: flex;
  flex: 1 0 20%;

  h2 {
    // border-left: 6px solid #333;
    border-left: 6px solid white;
    font-family: $poppins;
    font-weight: 700;
    padding-left: 6px;
    writing-mode: vertical-lr;
  }
}

.reveal-text {
  animation-name: reveal-text;
  color: #333;
  cursor: default;
  display: block;
  font-weight: 700;
  letter-spacing: -.05rem;
  position: relative;

  &::after {
    animation: revealer-text .6s 1.5s cubic-bezier(.55, .055, .675, .19) both;
    bottom: 0;
    background-image: linear-gradient(to right, #43e97b 0%, #38f9d7 100%);
    content: '';
    left: 0;
    mix-blend-mode: hard-light;
    pointer-events: none;
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    top: 0;
    z-index: 999;
  }
}

.poster-header,
.poster-footer {
  display: flex;
}

.poster-header {
  align-items: flex-end;
  flex-flow: row nowrap;
  margin-bottom: 24px;
  mix-blend-mode: darken;
  flex: 1 1 100%
}

.poster-body {
  animation: fadesIn .5s ease .5s normal forwards;
  display: flex;
  flex: 1 1 $width;
  flex-flow: row wrap;
  margin: auto;
  min-height: 180px;
  opacity: 0;
  visibility: hidden;
}

.poster-text {
  animation: fadesIn .3s ease .2s normal forwards;
  // display: none;
  flex: 1 0 $width;
  flex-flow: row wrap;
  opacity: 0;
  padding: 12px;
  visibility: hidden;

  h4 {
    flex: 1 0 $width;
    line-height: $h1;
    color: white;

    span {
      animation-name: reveal-text;
      //background-image: linear-gradient( 135deg, #FAD7A1 0%, #E96D71 100%);
      background: rgba(78,77,237, .4);
      cursor: default;
      display: inline;
      font-family: $poppins;
      font-weight: 400;
      line-height: 0;
      padding: 6px;
      position: relative;
      transition: background 1000ms linear;
      color: white;

      &::before {
        background-image: none;
      }

      &::after {
        animation: revealer-text 1.2s cubic-bezier(.55, .055, .675, .19) reverse;
        //background-image: linear-gradient( 135deg, #FAD7A1 0%, #E96D71 100%);
        background: rgba(78,77,237, .4);
        bottom: 0;
        content: '';
        left: 0;
        mix-blend-mode: screen;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: -2px;
        transform: scaleX(0);
        transform-origin: 0 50%;
        z-index: 999;
      }
    }
  }

  &.show {
    display: flex;
  }
}

.poster-author {
  display: flex;
  font-family: $poppins;
  font-weight: 400;
  margin-left: auto;
}

.naked--bar {
  background-color: transparent !important;
  box-shadow: none !important;
  border-bottom: none !important;
  border-bottom-color: transparent !important;
  justify-content: space-between;
  .md-icon {
    color: white !important;
    font-size: 2rem !important;
  }
}

@media screen and (min-width: $desktop-width) {
  .poster-header {
    flex: 0 1 $width;
  }

  .poster-heading {
    flex: 1 0 90%;

    h1 {
      // font-size: 4rem;
      font-weight: bold;
      font-size: 69px;
      line-height: 100%;
    }
  }

  .poster-sub-heading {
    flex: 1 0 10%;

    h2 {
      font-size: 3rem;
      padding-left: 24px;
      margin-left: 0;
    }
  }

  .poster-body {
    flex: 1 0 $width;
    width: $width;
  }

  .poster-text {
    margin: 0 auto;
    max-width: 600px;
  }
}

@keyframes reveal-text {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}
@keyframes revealer-text {

  0%, 50% {
    transform-origin: 0 50%;
  }

  60%, 100% {
    transform-origin: 100% 50%;
  }

  60% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

@keyframes mutate {
  0% {
    height: 0;
  }
  100% {
    height: 150%;
  }
}

@keyframes fadesIn {

  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.giphy-bck {
  background: transparent !important;
}
</style>
