// import userService from '../services/users';

export default {
  SET_ROOT_IP(state, rootIp) {
    state.rootIp = rootIp;
    localStorage.setItem('fac3d-inference-ip', rootIp);
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_USER_ID(state, uid) {
    state.user.id = uid;
    state.userId = uid;
    // userService.writeUserData(uid, state.user.email, state.user.displayName || '');
  },
  SET_USER_LOGGED_IN(state, res) {
    state.isUserLoggedIn = res;
  },
  SET_USER_SUBSCRIPTION(state, storedSubscription) {
    state.userSubscription = storedSubscription;
  },
  SET_USER_PAID_DATE(state, paidDate) {
    state.lastPaymentDate = paidDate;
  },
  SET_INSTANCE_ERROR(state, err) {
    state.instanceError = err;
  },
  SET_IMAGE(state, image) {
    state.actionImage = image;
  },
  SET_IMAGE_NAME(state, image) {
    state.userImageName = image;
  },
  SET_RESULT_IMAGE(state, image) {
    state.finalImage = image;
  },
  TOGGLE_LOADING(state) {
    state.loading = !state.loading;
  },
  SET_LOADING(state, res) {
    state.loading = res;
  },
  SET_ALERT(state, alertObject) {
    state.alertObject = alertObject;
  },
  SET_PRO_OBJECT(state, object) {
    state.goProObject = object;
  },
  SET_ZIP_DOWNLOAD(state, fn) {
    state.zipDirectory = fn;
  },
  SET_SELECTED_SUB(state, subscriptionType) {
    state.selectedSubscription = subscriptionType;
  },
  SET_SUB_EXP(state, expired) {
    state.subscriptionExpired = expired;
  },
  INCREMENT_QUOTA(state) {
    state.remainingQuota += 1;
  },
  DECREMENT_QUOTA(state) {
    state.remainingQuota -= 1;
  },
  GET_USER_QUOTA(state, snapshot) {
    state.userSnapshot = snapshot;
    if (snapshot) state.remainingQuota = snapshot.remainingQuota;
  },
  SET_JOB_ID(state, jobId) {
    state.jobId = jobId;
  },
  SET_QUEUE_ERROR(state, error) {
    state.queueError = error;
  },
  SET_QUEUE(state, queueState) {
    state.queueDone = queueState;
  },
  SET_CONFIRMATION_POPUP(state, status) {
    state.showLeavePageConfirmation = status;
  },
  SET_SNACKBAR(state, obj) {
    state.snackbarObject = { ...obj };
    setTimeout(() => {
      state.snackbarObject = {};
    }, 4000);
  },
  SET_USER_BROWSER(state, browser) {
    state.userBrowser = browser;
  },
  SET_USER_BRAND(state, brand) {
    state.userBrand = brand;
  },
};
