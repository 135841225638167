import firebase from 'firebase';

export default {
  writeUserData(userId, email, name) {
    // const idx = email.indexOf('@');
    // const uEmail = email.substring(0, idx);
    return new Promise((resolve, reject) => {
      firebase.database().ref(`users/${userId}`).set({
        displayName: name,
        email,
        lastLogin: new Date(),
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  getUserData(userId) {
    return new Promise((resolve, reject) => {
      firebase.database().ref(`/users/${userId}`).once('value')
        .then((snapshot) => {
          resolve(snapshot.val());
        })
        .catch((err) => reject(err));
    });
  },
  getSubscriptionData(userId) {
    return new Promise((resolve, reject) => {
      firebase.database().ref(`/subscribers/${userId}`).get()
        .then((snapshot) => {
          if (snapshot.exists()) {
            resolve(snapshot.val());
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  storeUserFilename(userId, newData) {
    const userRef = firebase.database().ref(`users/${userId}`);
    // if (userRef) {
    //   // userRef.child(`${userId}`).update({ filename: newData.filename });
    //   userRef.update(newData);
    // }
    return new Promise((resolve, reject) => {
      userRef.update({
        filename: newData,
      })
        .then(() => {
          resolve();
        })
        .catch((err) => reject(err));
    });
  },
  updateUserSubscription(userId, remainingQuota, subscriptionType) {
    const subs = firebase.database().ref('subscribers');
    const dataLoad = {
      userId,
      subscriptionType,
      remainingQuota,
      paidDate: new Date(),
    };
    subs.once('value').then((snap) => {
      const arr = snap.val();
      if (!arr) {
        const newSubRef = firebase.database().ref(`subscribers/${userId}`);
        newSubRef.set(dataLoad);
      }
      if (Object.keys(arr).includes(userId)) {
        // subscriber exists
        // const userRef = subs.child(userId);
        const userRef = firebase.database().ref(`subscribers/${userId}`);
        userRef.update(dataLoad);
      } else {
        // no subscriber found: new subscriber
        // const newSubRef = subs.push(userId); // local reference
        const newSubRef = firebase.database().ref(`subscribers/${userId}`);
        newSubRef.set(dataLoad);
      }
    });
  },
  pushUserData(userId, newData) {
    const newKey = firebase.database().ref().child('users/images').push().key;
    const updates = {};
    updates[`users/images${newKey}`] = newData;
    // updates[`users/${userId}/${newKey}`] = newData;
    return firebase.database().ref().update(updates);
  },
};
