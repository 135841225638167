<template>
  <div class="home">
    <div class="md-layout md-gutter md-alignment-space-around-space-around md-layout">
      <button class="raised-btn gradient-action-btn"
              v-if="!isUserSubscribed"
              @click.prevent="onPro">
        Go Pro ⭐
      </button>
      <button class="raised-btn faded-btn"
        @click.prevent="onDemo">
        <slot>{{ getIsUserLoggedIn ? userLabel : 'Sign Up🦸‍♀' }}</slot>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Actions',
  computed: {
    ...mapGetters([
      'getUserImage', 'getIsUserLoggedIn', 'getZipDirectory',
      'getRemainingQuota', 'getInstanceError', 'isUserSubscribed',
    ]),
    userLabel() {
      return this.isUserSubscribed ? 'Convert 🦾' : 'Try for free 🎁';
    },
  },
  data() {
    return {
      demo: false,
      downloading: false,
      visualizing: false,
    };
  },
  methods: {
    onDemo() {
      return this.getIsUserLoggedIn
        ? this.$router.push({ name: 'Fac3dMain' }) : this.$router.push({ name: 'Register' });
    },
    onPro() {
      this.$store.dispatch('setProAlert', {
        show: true,
        title: 'Go Pro',
        type: 'default',
      });
    },
  },
  mounted() {
    this.$store.commit('SET_ZIP_DOWNLOAD', null);
    this.$on('cancelProcess', () => {
      this.demo = !this.demo;
    });
  },
};
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  z-index: 10;
}
.md-gutter {
  padding: 3rem;
}
.upload-section {
  margin-top: 1rem;
}
</style>
