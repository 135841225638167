import fileService from '../services/fileServices';
import authService from '../services/auth';
import instanceService from '../services/instance';
import userService from '../services/users';

export default {
  checkApi({ commit }) {
    instanceService.pingApi()
      .then(() => {
        commit('SET_INSTANCE_ERROR', null);
      }, (err) => {
        commit('SET_INSTANCE_ERROR', 'Instance is not available.');
        throw new Error(err);
      });
  },
  getInstanceUrl({ commit }) {
    instanceService.getGenesisInstance()
      .then((res) => {
        const { instance } = res.data.result;
        if (instance.public_ip && instance.status !== 'stopped') commit('SET_ROOT_IP', instance.public_ip);
        else {
          commit('SET_ROOT_IP', process.env.VUE_APP_API_ENDPOINT);
          commit('SET_INSTANCE_ERROR', 'Genesis Instance is stopped!');
        }
      }, () => {
        commit('SET_ROOT_IP', '');
        commit('SET_INSTANCE_ERROR', 'Genesis Instance is not accessible!');
        throw new Error('Genesis Instance is not accessible!');
      });
  },
  setImageForUser({ commit }, image) {
    commit('SET_IMAGE', image);
  },
  setAlert({ commit }, alertObject) {
    commit('SET_ALERT', alertObject);
  },
  setUserSubscription({ commit }, subscription) {
    commit('SET_USER_SUBSCRIPTION', subscription);
  },
  selectSubscription({ commit }, subscriptionType) {
    commit('SET_SELECTED_SUB', subscriptionType);
  },
  clearData({ state }) {
    state.isQueueDone = false;
    state.queueError = null;
    state.zipDownload = null;
    state.zipDirectory = '';
    state.jobId = '';
    state.finalImage = null;
  },
  downloadZipFiles({ state }) {
    return new Promise((resolve, reject) => {
      fileService.getZip(state.zipDirectory)
        .then((res) => {
          state.zipDownload = res.data;
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  },
  setProAlert({ commit }, proObject) {
    commit('SET_PRO_OBJECT', proObject);
  },
  tryUserLogin({ commit, dispatch }, form) {
    return new Promise((resolve, reject) => {
      authService.login(form)
        .then(async (res) => {
          if (res.user !== undefined) {
            commit('SET_USER', res.user);
            commit('SET_USER_LOGGED_IN', true);
            dispatch('onSuccessfulLogin');
          } else resolve(res);
        })
        .catch((err) => {
          commit('SET_USER_LOGGED_IN', false);
          commit('SET_SNACKBAR', {
            show: true,
            content: err.message,
          });
          reject(err);
        });
    });
  },
  registerUser({ commit }, form) {
    return new Promise((resolve, reject) => {
      authService.register(form)
        .then(async (res) => {
          if (res.user !== undefined) {
            commit('SET_USER', res.user);
            commit('SET_USER_LOGGED_IN', true);
            resolve(res);
          }
        })
        .catch((err) => {
          commit('SET_USER_LOGGED_IN', false);
          reject(err);
        });
    });
  },
  onSuccessfulLogin({ commit }) {
    commit('SET_SNACKBAR', {
      show: true,
      content: 'You logged in successfully!',
    });
  },
  trySocialLogin({ commit, dispatch }, type) {
    return new Promise((resolve, reject) => {
      if (type === 'twitter') {
        authService.loginWithTwitter()
          .then((res) => {
            commit('SET_USER', res.data);
            commit('SET_USER_LOGGED_IN', true);
            dispatch('onSuccessfulLogin');
            resolve(res);
          })
          .catch((err) => {
            commit('SET_USER_LOGGED_IN', false);
            reject(err);
          });
      } else if (type === 'google') {
        authService.loginWithGoogle()
          .then((res) => {
            commit('SET_USER', res.data);
            commit('SET_USER_LOGGED_IN', true);
            dispatch('onSuccessfulLogin');
            resolve(res);
          })
          .catch((err) => {
            commit('SET_USER_LOGGED_IN', false);
            reject(err);
          });
      }
    });
  },
  userLogout({ commit }) {
    return new Promise((resolve, reject) => {
      authService.logout()
        .then((res) => {
          commit('SET_USER', null);
          commit('SET_USER_LOGGED_IN', false);
          commit('SET_SNACKBAR', {
            show: true,
            content: 'You logged out.',
          });
          resolve(res);
        }, (err) => reject(err));
    });
  },
  async getUser({ state, commit }, user) {
    commit('SET_USER_LOGGED_IN', user !== null);
    if (user) {
      if (Object.keys(state.user).length === 0) {
        if (user) await userService.writeUserData(user.uid, user.email, user.displayName);
      }
      commit('SET_USER', user);
    } else {
      commit('SET_USER', null);
    }
  },
  storeJobId({ commit }, jobId) {
    commit('SET_JOB_ID', jobId);
  },
  getResultFromQueue({ state, commit, dispatch }) {
    commit('SET_QUEUE', null);
    return new Promise((resolve, reject) => {
      fileService.getResultFromQueue(state.jobId)
        .then((res) => {
          if (res.status === 200) {
            const temp = res.data.fileName.split('.')[0];
            dispatch('storeUserFilename', temp);
            // dispatch('downloadPreviewFile', state.zipDirectory);
            commit('SET_QUEUE', true);
          }
          resolve(res);
        })
        .catch((err) => {
          commit('SET_QUEUE', err);
          reject(err);
        });
    });
  },
  storeUserFilename({ state, commit }, filename) {
    commit('SET_ZIP_DOWNLOAD', filename);
    return new Promise((resolve, reject) => {
      userService.storeUserFilename(state.userId, filename)
        .then((res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  },
  getPlyFile({ state }) {
    if (state.instanceError && state.instanceError.length > 0) return Promise.reject();
    return new Promise((res, rej) => {
      fileService.getPlyFile(state.zipDirectory)
        .then((response) => {
          // parse PLY file
          res(response);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  downloadPreviewFile({ state, commit }) {
    return new Promise((resolve, reject) => {
      fileService.getPreviewImage(state.zipDirectory)
        .then((response) => {
          commit('SET_RESULT_IMAGE', response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateUserSubscription({ state }, subscriptionType) {
    if (subscriptionType === '1') state.remainingQuota += 1;
    else state.remainingQuota += 5;
    userService.updateUserSubscription(state.userId, state.remainingQuota, subscriptionType);
    return Promise.resolve();
  },
  checkUserQuota({ state, commit }) {
    return new Promise((resolve, reject) => {
      userService.getSubscriptionData(state.userId)
        .then((res) => {
          // check if Subscription date is older than a month from today:
          const data = res[state.userId];
          if (!data) {
            commit('SET_SUB_EXP', true);
            resolve(false);
          }
          if (!data.subscriptionType) {
            commit('SET_SUB_EXP', true);
          } else {
            const dt = new Date(data.paidDate);
            const oneMonthAgo = new Date();
            oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
            if (+oneMonthAgo > +dt) {
              commit('SET_SUB_EXP', true); // set verdict: passed due date
              commit('SET_SELECTED_SUB', data.subscriptionType); // set stored subscriptionType
              commit('SET_USER_PAID_DATE', data.paidDate);
            } else {
              commit('SET_SUB_EXP', false); // set verdict: still valid
              commit('SET_SELECTED_SUB', ''); // set stored subscriptionType
              commit('SET_USER_PAID_DATE', data.paidDate);
              // update on Firebase if just expired:
              // if (data.subscriptionType
              //   && (data.subscriptionType.length > 0 || Number(data.subscriptionType) !== 0)) {
              //   userService.updateUserSubscription(state.userId, 0, 0)
              //     .then(() => {
              //       console.log('subscription updated: EXPIRED.');
              //     });
              // }
            }
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
