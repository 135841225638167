export const MIDDLEWARE = 'https://api.fac3d.xyz';

export const GENESIS_ROOT = 'https://api.genesiscloud.com';
export const GENESIS_INSTANCE = '/compute/v1/instances/';

export const INFERENCE = '/infere';
export const ZIP_DOWNLOAD = '/download-result';
export const PLY_DOWNLOAD = '/get-ply';
export const JOB_RESULT = '/results';
export const PREVIEW_RESULT = '/get-preview';
