import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../components/Actions.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/support/:type',
    name: 'SupportView',
    component: () => import(/* webpackChunkname: "supportBuffer" */ '../views/SupportView.vue'),
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: () => import(/* webpackChunkname: "authView" */ '../views/Auth.vue'),
  },
  {
    path: '/auth/register',
    name: 'Register',
    component: () => import(/* webpackChunkname: "authView" */ '../views/Auth.vue'),
  },
  {
    path: '/loading-page',
    name: 'WaitingView',
    component: () => import(/* webpackChunkname: "waitingView" */ '../components/Waiting.vue'),
    // beforeEnter: (to, from, next) => {
    //   if (store.state.isUserLoggedIn) next();
    //   else next(false);
    // },
  },
  {
    path: '/main',
    name: 'Fac3dMain',
    component: () => import(/* webpackChunkName: "fac3dMain" */ '../views/Main.vue'),
  },
  {
    path: '/visualizer',
    name: 'Fac3dVisualizer',
    component: () => import(/* webpackChunkName: "fac3dVisualizer" */ '../views/ModelShowcase.vue'),
  },
  {
    path: '/expressions',
    name: 'Fac3dExpressions',
    component: () => import(/* webpackChunkName: "fac3dExpressions" */ '../views/ExpressionShowcase'),
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: () => import(/* webpackChunkName: "userProfile" */ '../views/UserProfile.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.isUserLoggedIn) next();
      else next(false);
    },
  },
  {
    path: '/user-billing',
    name: 'UserBilling',
    component: () => import(/* webpackChunkName: "userBilling" */ '../views/Billing.vue'),
  },
  {
    path: '/terms',
    name: 'TermsOfService',
    component: () => import(/* webpackChunkName: "termsOfService" */ '../views/ToS.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
