import firebase from 'firebase';

export default {
  register(form) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(form.email, form.password)
        .then((data) => {
          data.user
            .updateProfile({
              displayName: form.name,
            })
            .then((res) => {
              resolve(res);
            });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  login(form) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(form.email, form.password)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  logout() {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return new Promise((resolve, reject) => {
      firebase.auth().signInWithRedirect(provider)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loginWithTwitter() {
    const provider = new firebase.auth.TwitterAuthProvider();
    return new Promise((resolve, reject) => {
      firebase.auth().signInWithRedirect(provider)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
