<template>
  <div class="ad-container" style="overflow:hidden;">
<!--    <Adsense-->
<!--      data-ad-client="ca-pub-4439890199834207"-->
<!--      data-ad-slot="1234567890" data-ad-format="auto"-->
<!--      data-full-width-responsive="true">-->
<!--    </Adsense>-->
    <div id="adsgoeshere" style="background: #1d1f29; padding-top:60px; text-align: center;"
         v-html="adsenseContent"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      adsenseContent: '',
    };
  },
  async mounted() {
    // const elem = document.querySelector('.adswrapper');
    // elem.style.maxHeight = '44px !important';
    this.adsenseContent = document.getElementById('divadsensedisplaynone').innerHTML;
  },
};
</script>
<style scoped>
.ad-container {
  max-height: 5px !important;
}
</style>
