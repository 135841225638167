import axios from 'axios';
import { MIDDLEWARE } from './api';

export default {
  pingApi() {
    return new Promise((resolve, reject) => {
      axios.get(MIDDLEWARE)
        .then((res) => {
          resolve(res);
        }, (err) => reject(err));
    });
  },
  getGenesisInstance() {
    const url = process.env.base === 'production'
      ? '/.netlify/functions/genesis_func' : 'https://fac3d.xyz/.netlify/functions/genesis_func';
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then((res) => {
          resolve(res);
        }, (err) => reject(err));
    });
  },
};
