import Vue from 'vue';
import {
  MdButton, MdContent, MdList, MdDialogConfirm,
  MdDialog, MdTabs, MdField, MdRadio, MdCheckbox,
  MdProgress, MdDialogAlert, MdCard, MdDrawer,
  MdMenu, MdToolbar, MdIcon, MdSnackbar,
  MdDivider,
} from 'vue-material/dist/components';
import firebase from 'firebase';
import VueSocialSharing from 'vue-social-sharing';
import UniqueId from 'vue-unique-id';
import Ads from 'vue-google-adsense';
import App from './App.vue';
// import './registerServiceWorker';
import wb from './registerServiceWorker';
import router from './router';
import store from './store';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import 'vue-material/dist/theme/default-dark.css';

// region Material Design imports and settings
Vue.use(MdButton);
Vue.use(MdContent);
Vue.use(MdDialog);
Vue.use(MdList);
Vue.use(MdTabs);
Vue.use(MdField);
Vue.use(MdProgress);
Vue.use(MdDialogAlert);
Vue.use(MdDialogConfirm);
Vue.use(MdCard);
Vue.use(MdMenu);
Vue.use(MdDrawer);
Vue.use(MdToolbar);
Vue.use(MdIcon);
Vue.use(MdRadio);
Vue.use(MdCheckbox);
Vue.use(MdSnackbar);
Vue.use(MdDivider);
Vue.use(UniqueId);
Vue.use(VueSocialSharing);

Vue.use(require('vue-script2'));

Vue.use(Ads.Adsense);

Vue.prototype.$workbox = wb;

Vue.material = {
  // activeness of ripple effect
  ripple: false,
  theming: {},
  locale: {
    // range for datepicker
    startYear: 1900,
    endYear: 2099,
    // date format for date picker
    dateFormat: 'dd/MM/yyyy',
    // i18n strings
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    shorterDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    shorterMonths: ['J', 'F', 'M', 'A', 'M', 'Ju', 'Ju', 'A', 'Se', 'O', 'N', 'D'],
    // `0` stand for Sunday, `1` stand for Monday
    firstDayOfAWeek: 1,
    cancel: 'Cancel',
    confirm: 'OK',
  },
};
// endregion Material Design imports and settings

Vue.config.productionTip = false;

const firebaseConfigOptions = {
  apiKey: 'AIzaSyAtiboFyB6ydJbncQDr9xkaXtjqVrWETWc',
  authDomain: 'fac3d-301320.firebaseapp.com',
  projectId: 'fac3d-301320',
  storageBucket: 'fac3d-301320.appspot.com',
  messagingSenderId: '814141552954',
  appId: '1:814141552954:web:c12d285975290465003803',
  measurementId: 'G-RK1MT2KEP5',
};

firebase.initializeApp(firebaseConfigOptions);
firebase.analytics();
Vue.prototype.$analytics = firebase.analytics();

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    // User is signed in.
    store.dispatch('getUser', user);
    store.commit('SET_USER_ID', user.uid);
    store.commit('SET_USER', user);
  } else {
    // No user is signed in.
    store.commit('SET_USER', {});
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
