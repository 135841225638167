import axios from 'axios';
import {
  ZIP_DOWNLOAD,
  PLY_DOWNLOAD,
  JOB_RESULT,
  PREVIEW_RESULT,
} from './api';

export default {
  getZip(dirName) {
    const ip = `https://api.fac3d.xyz${ZIP_DOWNLOAD}`;
    const body = {
      dirName,
    };
    return new Promise((resolve, reject) => {
      axios({
        url: ip,
        method: 'POST',
        responseType: 'blob',
        data: body,
      }).then((response) => {
        // if (brand === 'apple') {
        //   // TODO: handle for Apple.
        // } else {
        // }
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'fac3d_Results.zip');
        document.body.appendChild(link);
        link.click();
        resolve(response);
      }, (err) => reject(err));
    });
  },
  getPlyFile(dirName) {
    return new Promise((resolve, reject) => {
      axios({
        url: `https://api.fac3d.xyz${PLY_DOWNLOAD}/${dirName}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((response) => {
          const dtt = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
          resolve(dtt);
        }, (err) => reject(err));
    });
  },
  getResultFromQueue(jobId) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}${JOB_RESULT}/${jobId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPreviewImage(filename) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_API_ENDPOINT}${PREVIEW_RESULT}/${filename}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((res) => {
          const blob = window.URL.createObjectURL(new Blob([res.data], { type: 'application/octet-stream' }));
          resolve(blob);
        }, (err) => reject(err));
    });
  },
};
