<template>
  <md-dialog class="go-pro-dialog" :md-active.sync="getProObject.show">
    <md-card>
      <md-card-header>
        <div class="md-title"><h2>{{ getProObject.title }}⭐</h2></div>
      </md-card-header>

      <md-card-content>
        <div :class="subscriptionType === '2' ?
          'radio-wrapper --selected --big' : 'radio-wrapper --big'">
          <md-radio v-model="subscriptionType" value="2" class="md-primary">
            <div class="radio-container">
              <ul>
                <li>No ads</li>
                <li>Unlimited runs*</li>
                <li>Skip the line with a faster response</li>
                <li>Access to 3D modelling beta features</li>
              </ul>
              <h3 class="price-span">I'm in 🦾 (<strong>$4.99/ month</strong>)</h3>
            </div>
          </md-radio>
        </div>
        <!-- <div :class="subscriptionType === '1' ? 'radio-wrapper --selected' : 'radio-wrapper'">
          <md-radio v-model="subscriptionType" disabled value="1" class="md-primary">
            <div class="radio-container">(coming soon)
              <ul>
                <li>No ads</li>
                <li>3 runs</li>
              </ul>
              <h3 class="price-span">
                Just a 🍺 this time <strong>($0.99)</strong>
              </h3>
            </div>
          </md-radio>
        </div> -->
      </md-card-content>

      <md-card-actions md-alignment="space-between">
        <md-button class="md-raised md-accent" @click.prevent="onCancel">
          Maybe Later
        </md-button>
        <md-button class="md-raised md-primary"
          :disabled="noSubscription" @click.prevent="onContinue">
          Continue
        </md-button>
      </md-card-actions>
    </md-card>
  </md-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
// import StripeComponent from './StripeComponent.vue';
// import PayPalComponent from './PayPalComponent.vue';

export default {
  name: 'GoProDialog',
  // components: {
  //   GooglePay,
  // },
  computed: {
    ...mapGetters(['getProObject']),
    noSubscription() {
      return this.subscriptionType === '' || this.subscriptionType === 0;
    },
  },
  data() {
    return {
      subscriptionType: '2',
      options: {},
    };
  },
  methods: {
    onCancel() {
      this.$store.dispatch('setProAlert', {
        show: false,
        type: 'default',
        title: '',
      });
    },
    async onContinue() {
      this.$emit('SUBSCRIPTION_CONTINUE', this.subscriptionType);
      await this.$store.dispatch('selectSubscription', this.subscriptionType);
      this.onCancel();
      await this.$router.push(`/support/${this.subscriptionType}`);
    },
  },
  mounted() {
    this.$on('payment-cancelled', async () => {
      console.log('event fired: PAYMENT CANCELLED!');
    });
  },
};
</script>
<style lang="scss">
.go-pro-dialog {
  .md-dialog-container.md-theme-default {
    background: rgba(0, 0, 0, 1) !important;
    backdrop-filter: blur(1px) !important;
    //padding-bottom: 5rem;
  }
  .md-radio.md-theme-default.md-checked.md-primary .md-radio-container:after {
    background-color: white;
  }
  //.md-card-actions {
  //  position: absolute;
  //  bottom: 0;
  //  width: 100%;
  //}
  .radio-wrapper {
    margin: 1rem 0;
    padding: 0 0.5rem;
    min-height: 20vh;
    background: rgba(0,0,0,0.15);
    &.--big {
      min-height: 30vh;
    }
    &.--selected {
      background: rgba(0,0,0,0.75);
    }
    h3 {
      margin: 0 !important;
    }
  }
  .md-card {
    font-family: 'Poppins', sans-serif;
    // background: #ff577d !important;
    background: transparent !important;
    height: inherit;
    padding: 2rem;
    .md-card-content {
      font-size: 16px;
    }
  }
  .md-dialog-container.go-pro-dialog {
    border-radius: 20px !important;
  }
  h2, .md-card-content {
    color: white;
  }
  .price-span {
    font-size: 32px;
    font-family: 'Mono';
    line-height: 32px;
    // color: white !important;
    // padding: 2rem .5rem;
    // text-align: center;
    strong {
      text-decoration: underline;
    }
  }
  .cancel-btn {
    margin-right: 3rem !important;
  }
}
</style>
